import React from "react";
import './b1_profile_enroll_list.css'
import LayoutB1Profile from "../../components/layout-b1-profile"
import { navigate } from "@reach/router"

export default class Profile extends React.Component{
  toEnrollDetail=(e)=>{
    navigate('/demos/themeB1/race_enroll_info')
  }
  render() {
    const enrollsinfo=[{},{}]
    const enrolllist=enrollsinfo.map((enroll,index)=>
      <div className='table-content' key={index} >
        <div style={{flex:2}}>
          太湖马拉松-全马
        </div>
        <div style={{flex:1}}>张迈</div>
        <div style={{flex:1}}>等待审核</div>
        <div style={{flex:1.5}}>2019-11-05 10:50</div>
        <div style={{flex:1}}onClick={()=>this.toEnrollDetail(index)}>
          <div className='view-detail'>
            查看详情
          </div>
        </div>
      </div>)
    const enrolllistPhone=enrollsinfo.map((enroll,index)=>
      <div className='table-content-phone' key={index} >
        <div className="top-half">
          <div >
            太湖马拉松-全马
          </div>
          <div className='view-detail' onClick={()=>this.toEnrollDetail(index)}>
            查看详情
          </div>
        </div>
        <div className="bottom-half">
          <div >张迈</div>
          <div >等待审核</div>
          <div >2019-11-05 10:50</div>
        </div>
      </div>)
    return(
      <LayoutB1Profile location={this.props.location} >
        <div className="enroll-list">
          <div className="table-item">
            <div style={{flex:2}}>报名项目</div>
            <div style={{flex:1}}>报名人</div>
            <div style={{flex:1}}>报名状态</div>
            <div style={{flex:1.5}}>报名时间</div>
            <div style={{flex:1}}></div>
          </div>
          <div style={{width:'100%'}} className="enroll-list-content">{enrolllist}</div>
          <div className="enroll-list-content-phone">{enrolllistPhone}</div>

        </div>
      </LayoutB1Profile>
    )
  }
}